import uniq from 'lodash.uniq'
import * as filters from '@/Filters'
import { firstBy } from 'thenby'
import TiebreakDefaults from '@/classes/Tiebreaks'

const rankTeams = (teams, notes, complete, tiebreakers, matches) => {
  var changed = true
  var cycle = 1
  const tbs = tiebreaks(tiebreakers)

  while (changed && hasTies(teams)) {
    // console.log('here')
    var ranks = uniq(teams.map(t => t.rank)).sort(firstBy(Number))
    var r = ranks.find(f => teams.filter(t => t.rank === f).length > 1)
    var _teams = teams.filter(f => f.rank === r)
    if (cycle++ > 1) notes.push({ text: `${_teams.length}-way tie for ${filters.ordinal(r)}`, header: true })
    const n = notes.length ? notes : []

    if (cycle !== 0) {
      changed = previousFinish(_teams, n)
    }
    tbs.some(tb => {
      changed = runTiebreak(tb.name, _teams, n, complete, matches)
      // console.log(`${tb.name} = ${changed}`)
      return changed
    })

    // if (!changed) changed = matchWinRatio(_teams, notes)
    // if (!changed) changed = setWinRatio(_teams, notes)
    // if (!changed) changed = headToHead(_teams, notes)
    // if (!changed) changed = overallPoints(_teams, notes)
    // if (!changed) changed = overallPointRatio(_teams, notes)
    // if (!changed) changed = previousSeed(_teams, notes, complete)
  }
}

const runTiebreak = (name, teams, notes, complete, matches) => {
  switch (name) {
    case 'DUAL_RECORD':
      return dualRecord(teams, notes, matches)
    case 'HEAD_2_HEAD':
      return headToHead(teams, notes, matches)
    case 'MATCH_WIN_RATIO':
      return matchWinRatio(teams, notes)
    case 'SET_WIN_RATIO':
      return setWinRatio(teams, notes)
    case 'POINTS_BETWEEN':
      return headToHead(teams, notes)
    case 'OVERALL_POINTS':
      return overallPoints(teams, notes)
    case 'ORIGINAL_SEED':
      return previousSeed(teams, notes, complete)
    case 'POINTS_FOR':
      return pointsFor(teams, notes, complete)
  }
}

const tiebreaks = (breaks) => {
  return breaks ? breaks.split(',').map(m => {
    return TiebreakDefaults.find(f => f.name === m.trim())
  }) : TiebreakDefaults
}

const hasTies = (teams) => {
  return teams.length !== new Set(teams.map(m => m.rank)).size
}

const dualRecord = (teams, notes) => {
  const dualWinRatios = uniq(teams.map(r => r.dualWinRatio)).sort().reverse()
  if (dualWinRatios.length === 1) {
    notes.push({ text: `DR: All teams have match win ratio of ${filters.fixed3(dualWinRatios[0])}` })
    return false
  }
  let i = teams[0].rank
  const iOg = teams[0].rank

  const matchesPlayed = uniq(teams.map(m => m.matchesPlayed)).length
  if (matchesPlayed > 1) {
    if (dualWinRatios.includes(1)) {
      var undef = teams.filter(f => f.dualWinRatio === 1)
      undef.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `DR: ${filters.ordinal(i)} - ${x.name} (Undefeated).` })
      })
      i += undef.length
      var def = teams.filter(f => f.dualWinRatio !== 1)
      def.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `DR: ${filters.ordinal(i)} - ${x.name} (Not undefeated).` })
      })
      return i !== iOg
    }
    if (dualWinRatios.filter(f => f <= 0).length) {
      const hasAWin = teams.filter(f => f.dualWinRatio > 0)
      hasAWin.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `DR: ${filters.ordinal(i)} - ${x.name} (Has a win).` })
      })
      i += hasAWin.length
      var def2 = teams.filter(f => f.dualWinRatio <= 0)
      def2.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `DR: ${filters.ordinal(i)} - ${x.name} (No wins).` })
      })
      return i !== iOg
    }
    notes.push({ text: 'DR: Teams have differing # of matches played.' })
    return false
  }

  const ranks = []
  dualWinRatios.forEach(r => {
    const _teams = teams.filter(f => f.dualWinRatio === r)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `DR: ${filters.ordinal(i)} - ${x.name} (${filters.fixed2(x.dualWinRatio)} ratio).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `DR: ${filters.formatArray(ranks)} was decided.` })
  return true
}

const matchWinRatio = (teams, notes) => {
  const matchWinRatios = uniq(teams.map(r => r.matchWinRatio)).sort().reverse()
  if (matchWinRatios.length === 1) {
    notes.push({ text: `MWR: All teams have match win ratio of ${filters.fixed3(matchWinRatios[0])}` })
    return false
  }
  let i = teams[0].rank
  const iOg = teams[0].rank

  const matchesPlayed = uniq(teams.map(m => m.matchesPlayed)).length
  if (matchesPlayed > 1) {
    if (matchWinRatios.includes(1)) {
      var undef = teams.filter(f => f.matchWinRatio === 1)
      undef.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `MWR: ${filters.ordinal(i)} - ${x.name} (Undefeated).` })
      })
      i += undef.length
      var def = teams.filter(f => f.matchWinRatio !== 1)
      def.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `MWR: ${filters.ordinal(i)} - ${x.name} (Not undefeated).` })
      })
      return i !== iOg
    }
    if (matchWinRatios.filter(f => f <= 0).length) {
      const hasAWin = teams.filter(f => f.matchWinRatio > 0)
      hasAWin.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `MWR: ${filters.ordinal(i)} - ${x.name} (Has a win).` })
      })
      i += hasAWin.length
      var def2 = teams.filter(f => f.matchWinRatio <= 0)
      def2.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push({ text: `MWR: ${filters.ordinal(i)} - ${x.name} (No wins).` })
      })
      return i !== iOg
    }
    notes.push({ text: 'MWR: Teams have differing # of matches played.' })
    return false
  }

  const ranks = []
  matchWinRatios.forEach(r => {
    const _teams = teams.filter(f => f.matchWinRatio === r)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `MWR: ${filters.ordinal(i)} - ${x.name} (${filters.fixed2(x.matchWinRatio)} ratio).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `MWR: ${filters.formatArray(ranks)} was decided.` })
  return true
}

const setWinRatio = (teams, notes) => {
  const setWinRatios = uniq(teams.map(r => r.setWinRatio)).sort().reverse()
  if (setWinRatios.length === 1) {
    notes.push({ text: `SWR: All teams have set win ratio of ${filters.fixed3(setWinRatios[0])}` })
    return false
  }
  let i = teams[0].rank
  const iOg = teams[0].rank

  const setesPlayed = uniq(teams.map(m => m.setesPlayed)).length
  if (setesPlayed > 1) {
    if (setWinRatios.includes(1)) {
      var undef = teams.filter(f => f.setWinRatio === 1)
      undef.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push(`SWR: ${filters.ordinal(i)} - ${x.name} (Undefeated).`)
      })
      i += undef.length
      var def = teams.filter(f => f.setWinRatio !== 1)
      def.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push(`SWR: ${filters.ordinal(i)} - ${x.name} (Not undefeated).`)
      })
      return i !== iOg
    }
    if (setWinRatios.filter(f => f <= 0).length) {
      const hasAWin = teams.filter(f => f.setWinRatio > 0)
      hasAWin.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push(`SWR: ${filters.ordinal(i)} - ${x.name} (Has a win).`)
      })
      i += hasAWin.length
      var def2 = teams.filter(f => f.setWinRatio <= 0)
      def2.forEach(x => {
        x.rank = i
        x.dRank = i
        notes.push(`SWR: ${filters.ordinal(i)} - ${x.name} (No wins).`)
      })
      return i !== iOg
    }
    notes.push('SWR: Teams have differing # of setes played.')
    return false
  }

  const ranks = []
  setWinRatios.forEach(r => {
    const _teams = teams.filter(f => f.setWinRatio === r)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `SWR: ${filters.ordinal(i)} - ${x.name} (${filters.fixed2(x.setWinRatio)} ratio).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `SWR: ${filters.formatArray(ranks)} was decided.` })
  return true
}

// point diff
const overallPoints = (teams, notes) => {
  const pointsPlayed = uniq(teams.map(r => r.pointsPlayed)).sort(function (a, b) { return b - a })
  if (pointsPlayed.length > 1) {
    notes.push({ text: 'PD: All teams did not play the same number of points' })
    notes.push({ text: 'PD: Switching to point ratio' })
    return overallPointRatio(teams, notes)
  }
  let i = teams[0].rank
  const diffs = uniq(teams.map(r => r.pointDiff)).sort(function (a, b) { return b - a })
  if (diffs.length === 1) {
    notes.push({ text: `PD: All teams have a point differential of ${diffs[0]}` })
    return false
  }
  const ranks = []
  diffs.forEach(d => {
    const _teams = teams.filter(f => f.pointDiff === d)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `PD: ${filters.ordinal(i)} - ${x.name} (${x.pointDiff} point differential).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `PD: ${filters.formatArray(ranks)} decided.` })
  return true
}

// point ratio
const overallPointRatio = (teams, notes) => {
  let i = teams[0].rank
  const diffs = uniq(teams.map(r => r.pointDiffRatio)).sort(function (a, b) { return b - a })
  if (diffs.length === 1) {
    notes.push({ text: `PR: All teams have a point ratio of ${diffs[0]}` })
    return false
  }
  const ranks = []
  diffs.forEach(d => {
    const _teams = teams.filter(f => f.pointDiffRatio === d)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `PR: ${filters.ordinal(i)} - ${x.name} (${x.pointDiffRatio} point ratio).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `PR: ${filters.formatArray(ranks)} decided.` })
  return true
}

const previousSeed = (teams, notes, complete) => {
  teams.sort(firstBy('seed'))
  var seeds = [...new Set(teams.map(m => m.seed))]
  if (seeds.length === 1) {
    notes.push({ text: `PS: All teams have a seed of ${seeds[0]}` })
    return false
  }
  const ranksOg = uniq(teams.map(t => t.rank)).sort()
  let i = 0
  const ranks = []
  teams.forEach(x => {
    x.rank += i++
    if (complete) x.dRank = x.rank
    ranks.push(x.rank)
    notes.push({ text: `OS: ${filters.ordinal(x.rank)} - ${x.name} (Seeded ${x.seed})` })
  })
  const newRanks = uniq(teams.map(t => t.rank)).sort()
  if (JSON.stringify(ranksOg) !== JSON.stringify(newRanks)) {
    notes.push({ text: `OS: ${filters.formatArray(ranks)} decided.` })
    return true
  }
  return false
}

const pointsFor = (teams, notes, complete) => {
  let i = teams[0].rank
  const diffs = uniq(teams.map(r => r.pointsFor)).sort(function (a, b) { return b - a })
  if (diffs.length === 1) {
    notes.push({ text: `PF: All teams have total points of ${diffs[0]}` })
    return false
  }
  const ranks = []
  diffs.forEach(d => {
    const _teams = teams.filter(f => f.pointsFor === d)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `PF: ${filters.ordinal(i)} - ${x.name} (${x.pointsFor} total point).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `PD: ${filters.formatArray(ranks)} decided.` })
  return true
}

const previousFinish = (teams, notes) => {
  let i = teams[0].rank
  const diffs = uniq(teams.map(r => r.pFinish)).sort(function (a, b) { return a - b })
  if (diffs.length === 1) {
    diffs[0] !== 999 && notes.push({ text: `PD: All teams have a previous finish of ${diffs[0]}` })
    return false
  }
  const ranks = []
  diffs.forEach(d => {
    const _teams = teams.filter(f => f.pFinish === d)
    _teams.forEach(x => {
      x.rank = i
      x.dRank = i
      notes.push({ text: `PF: ${filters.ordinal(i)} - ${x.name} (${x.pFinish} previous finish).` })
    })
    ranks.push(filters.ordinal(i))
    i = i + _teams.length
  })
  notes.push({ text: `PF: ${filters.formatArray(ranks)} decided.` })
  return true
}

const headToHead = (teams, notes, matches) => {
  if (teams.length !== 2) {
    notes.push({ text: 'H2H: Can\'t be broken head to head - More than 2 teams' })
    return false
  }
  matches = matches ? matches.filter(match => match.isBetweenTeamId([teams[0].teamId, teams[1].teamId])) : []
  if (!matches.length) {
    notes.push({ text: 'H2H: Can\'t be broken head to head - No H2H' })
    return false
  }
  const incomplete = matches.find(f => !f.complete)
  if (!incomplete) {
    if (matches.length === 1) {
      const match = matches[0]
      if (match.tbWinner === 'split') {
        notes.push({ text: 'H2H: Can\'t be broken head to head - Split sets' })
        return false
      }
      if (teams[0].id === match.winningId) {
        teams[1].rank += 1
        teams[1].dRank += 1
      } else {
        teams[0].rank += 1
        teams[0].dRank += 1
      }
      const ranks = teams.map(t => t.rank).sort().map(x => filters.ordinal(x))
      notes.push({ text: `H2H: ${filters.formatArray(ranks)} decided by match ${match.id}` })
      return true
    } else {
      const t0 = matches.filter(f => f.winningTeam.teamId === teams[0].teamId)
      const t1 = matches.filter(f => f.winningTeam.teamId === teams[1].teamId)
      if (t0 === t1) {
        notes.push({ text: 'H2H: Can\'t be broken head to head - Split matches' })
      }
      if (t0 > t1) {
        teams[1].rank += 1
        teams[1].dRank += 1
      } else {
        teams[0].rank += 1
        teams[0].dRank += 1
      }
    }
  }
  notes.push({ text: 'H2H: Head to head not complete' })
  return false
}

export {
  rankTeams
}
