<template>
  <v-card color="grey lighten-4" class="pt-1">
    <v-container fluid class="pt-0" v-if="rounds && rounds.length">
      <v-alert
        type="warning"
        prominent
        :value="view.adminAndRefs && complete && !group.locked"
        transition="scale-transition"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{group.name}} looks ready to lock.</strong>
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click.stop="onLockToggle"
              :loading="loading"
              class="ml-3"
            >lock</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <!-- Team Summary -->
      <v-row dense>
        <v-col cols="12" class="text-h4 align-baseline d-flex">
          {{currentOption.name}}
          <v-menu
            bottom
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="color3"
                text x-small
                v-bind="attrs"
                v-on="on"
              >... more</v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in displayOptions"
                :key="i"
                @click="selectedOption = i"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-right">
          <manual-finish v-if="view.adminAndRefs && !group.locked && rankedTeams"
            @finish-manually="onFinishManually"
            :rankedTeams="rankedTeams"
            :showMatches="showMatches"
            :showDuals="showDuals"
            :tiebreakNotes="notes.length"
          ></manual-finish>
        </v-col>
        <v-col cols="12">
          <summary-table
            :teams="rankedTeams"
            :advanced="advanced"
            :division="division"
            :tournament="tournament"
            :showSummaries="showSummaries"
            :isMatch="showMatches"
            :isDuals="showDuals"
            :hideSeeds="hideSeeds"
            :dense="true"
            :totalPoints="totalPoints"
          ></summary-table>
        </v-col>
      </v-row>
      <!-- Pool Summary Buttons -->
      <v-row dense :justify="showTiebreaks ? 'space-between' : 'end'">
        <v-btn
          text
          small
          color="color3"
          class="pa-0 ma-0"
          @click.stop="dialog = true"
          v-if="showTiebreaks"
        >tie breaks</v-btn>
        <v-btn
          text
          small
          color="color3"
          class="pa-0 ma-0"
          v-if="$vuetify.breakpoint.smAndUp"
          @click.stop="advanced = !advanced"
        >{{advanced ? 'Simple' : 'Advanced'}}</v-btn>
      </v-row>
    </v-container>
    <v-container fluid class="pa-9" v-else>
      <v-alert type="info" :value="true" prominent text>
        No matches have been selected to be included in these standings
      </v-alert>
    </v-container>
    <!-- Tie break dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      v-if="showTiebreaks"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>How Ties Were Broken</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row >
            <v-col class="x12" >
              <v-list>
                <template v-for="(note, i) in notes">
                  <v-subheader v-if="note.header" :key="i">{{note.text}}</v-subheader>
                  <v-list-item v-else :key="i">{{note.text}}</v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <v-row dense justify="end">
            <v-col class="caption" v-if="false">
              {{tiebreakers.map(t => `${t.short} = ${t.text}`) | formatArray}}
            </v-col>
            <v-btn
              text
              color="color3"
              @click.stop="dialog = false"
            >close</v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
import { rankTeams } from '@/classes/Standings/TeamRanker'
import { firstBy } from 'thenby'
import SummaryTable from '@/components/Standings/SummaryTable.vue'
import ManualFinish from '@/components/Standings/ManualFinishDialog.vue'

export default {
  props: ['group', 'rounds', 'teamIds', 'division', 'standings', 'i', 'loading'],
  data () {
    return {
      advanced: false,
      dialog: false,
      editAll: false,
      summaries: false,
      notes: [],
      selectedOption: null,
      picker: false
    }
  },
  computed: {
    ...mapGetters([
      'view',
      'teamColors',
      'teamColorsInverse',
      'tournament',
      'getTeam',
      'getDivisionJProp'
    ]),
    teams () {
      return flatten(this.filteredRounds.map(m => m.allTeams)).filter(f => this.teamIds.includes(f.teamId))
    },
    matches () {
      return flatten(this.filteredRounds.map(m => m.allMatches))
    },
    mirrorMatches () {
      const frIds = this.filteredRounds.map(m => m.id)
      return this.tournament.crossDivMatches.length > 0 ? this.tournament.crossDivMatches.filter(f => frIds.includes(f.mirrorRoundId)) : []
    },
    complete () {
      return !this.rounds.filter(f => !f.complete).length
    },
    rankedTeams () {
      const d = this.showDuals
      const ids = this.group.locked ? this.group.summary.map(f => f.teamId) : this.teamIds
      var teams = ids.map(id => {
        const t = this.getTeam(id)
        const result = {
          seed: t.seed,
          teamId: id,
          name: t.name,
          playerNames: t.playerNames,
          rank: 1,
          pFinish: 999,
          dualWins: 0,
          dualLosses: 0,
          matchWins: 0,
          matchLosses: 0,
          setWins: 0,
          setLosses: 0,
          pointsFor: 0,
          pointsAgainst: 0,
          pointsPlayed: 0,
          get wins () { return d ? this.dualWins : this.matchWins },
          get losses () { return d ? this.dualLosses : this.matchLosses },
          get dualsPlayed () { return this.dualWins + this.dualLosses },
          get dualWinRatio () { return this.dualWins === 0 ? -this.dualLosses : this.dualWins / this.dualsPlayed },
          get matchesPlayed () { return this.matchWins + this.matchLosses },
          get matchWinRatio () { return this.matchWins === 0 ? -this.matchLosses : this.matchWins / this.matchesPlayed },
          get setsPlayed () { return this.setWins + this.setLosses },
          get setWinRatio () { return this.setWins === 0 ? -this.setLosses : this.setWins / this.setsPlayed },
          get pointDiff () { return this.pointsFor - this.pointsAgainst },
          get pointDiffRatio () { return this.matchesPlayed ? this.pointsFor + this.pointsAgainst === 0 ? 0 : this.pointsFor / this.pointsAgainst : null }
        }
        if (this.group.locked && this.currentOption.isLast) {
          const stats = this.group.summary.find(f => f.teamId === id)
          result.rank = stats.finish
          result.dualWins += stats.dualWins
          result.dualLosses += stats.dualLosses
          result.matchWins += stats.matchWins
          result.matchLosses += stats.matchLosses
          result.setWins += stats.setWins
          result.setLosses += stats.setLosses
          result.pointsFor += stats.pointsFor
          result.pointsAgainst += stats.pointsAgainst
          result.pointsPlayed += stats.pointsPlayed
        } else {
          this.teams.filter(f => f.teamId === id).forEach(e => {
            const stats = e.locked ? e.summary || e : e
            result.dualWins += stats.dualWins
            result.dualLosses += stats.dualLosses
            result.matchWins += stats.matchWins
            result.matchLosses += stats.matchLosses
            result.setWins += stats.setWins
            result.setLosses += stats.setLosses
            result.pointsFor += stats.pointsFor
            result.pointsAgainst += stats.pointsAgainst
            result.pointsPlayed += stats.pointsPlayed
          })
        }

        if (this.tournament.crossDivMatches.length > 0) {
          const myMatches = this.mirrorMatches.filter(f => f.activeTeamIds.includes(id) && f.complete)
          myMatches.forEach(m => {
            const iAmHome = m.homeIds.includes(id)
            if (iAmHome) {
              if (m.winner === 'home') result.matchWins += m.isMatch ? 1 : m.homeSetWins
              if (m.winner === 'away') result.matchLosses += m.isMatch ? 1 : m.awaySetWins
              if (m.winner === 'split') {
                result.matchWins += m.homeSetWins
                result.matchLosses += m.awaySetWins
              }
              result.setWins += m.homeSetWins
              result.setLosses += m.awaySetWins
              result.pointsFor += m.homePointsFor
              result.pointsAgainst += m.homePointsAgainst
            } else {
              if (m.winner === 'home') result.matchLosses += m.isMatch ? 1 : m.homeSetWins
              if (m.winner === 'away') result.matchWins += m.isMatch ? 1 : m.awaySetWins
              if (m.winner === 'split') {
                result.matchLosses += m.homeSetWins
                result.matchWins += m.awaySetWins
              }
              result.setWins += m.awaySetWins
              result.setLosses += m.homeSetWins
              result.pointsFor += m.awayPointsFor
              result.pointsAgainst += m.awayPointsAgainst
            }
            result.pointsPlayed += m.pointsPlayed
          })
        }
        return result
      })
      const notes = []
      const fr = this.standings.finishRoundIds
      if (fr.length) {
        const fTeams = flatten(this.filteredRounds.filter(f => fr.includes(f.id)).map(m => m.allTeams)).filter(f => f.locked)
        fTeams.forEach(t => {
          const team = teams.find(f => f.teamId === t.teamId)
          if (team && t.summary) {
            team.pFinish = t.summary.finish
          }
        })
      }

      // var ranked = teams.filter(m => m.rank < 999)
      // console.log(ranked)
      rankTeams(teams, notes, this.complete, this.tiebreaks, this.matches)
      // console.log(notes)
      this.updateNotes(notes)

      return teams.sort(firstBy('rank'))
    },
    tiebreaks () {
      const base = this.standings.tiebreaks || `${this.division.isDuals ? 'DUAL_RECORD,' : ''}MATCH_WIN_RATIO,SET_WIN_RATIO,HEAD_2_HEAD,OVERALL_POINTS,ORIGINAL_SEED`
      return base
    },
    showMatches () {
      return !!this.matches.find(f => f.isMatch)
    },
    showDuals () {
      return !!this.matches.find(f => f.isDual)
    },
    tieBreakNotes () {
      return ['this.pool.tiebreakNotes']
    },
    lockDTO () {
      return this.rankedTeams.map(team => {
        return {
          teamId: team.teamId,
          finish: team.rank,
          dualWins: team.dualWins,
          dualLosses: team.dualLosses,
          matchWins: team.matchWins,
          matchLosses: team.matchLosses,
          setWins: team.setWins,
          setLosses: team.setLosses,
          pointsFor: team.pointsFor,
          pointsAgainst: team.pointsAgainst
        }
      })
    },
    showSummaries () {
      return this.group.locked
    },
    showTiebreaks () {
      return this.tieBreakNotes && this.tieBreakNotes.length > 0 && !this.manuallyChanged
    },
    hideSeeds () {
      return this.getDivisionJProp(this.division.id, 'hideSeed')
    },
    currentOption () {
      var i = this.selectedOption === null ? this.displayOptions.length - 1 : this.selectedOption
      return this.displayOptions[i]
    },
    displayOptions () {
      const rounds = this.rounds.map(m => {
        return {
          id: m.id,
          name: m.name,
          n: m.number
        }
      }).sort(firstBy('n'))

      const options = []
      rounds.forEach((r, i) => {
        options.push({
          name: `${r.name} Results`,
          roundIds: [r.id],
          value: i
        })
        options.push({
          name: `${r.name} Standings`,
          roundIds: rounds.filter(f => f.n <= r.n).map(m => m.id)
        })
      })
      if (options.length) {
        options[options.length - 1].name = `${this.group.locked ? 'Final' : 'Current'} Standings`
        options[options.length - 1].isLast = true
      }

      return options
    },
    filteredRounds () {
      return this.currentOption ? this.rounds.filter(f => this.currentOption.roundIds.includes(f.id)) : this.rounds
    },
    totalPoints () {
      return this.standings && this.standings.tiebreaks && this.standings.tiebreaks.startsWith('POINTS_FOR')
    }
  },
  methods: {
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    updateNotes (notes) {
      this.notes = notes
    },
    getRep (teamId) {
      var x = this.getTeam(teamId)
      return x.getRep(this.division.repField)
    },
    onFinishManually (finishes) {
      this.lock(finishes)
    },
    onLockToggle () {
      if (this.group.locked) {
        this.unlock()
      } else {
        this.lock()
      }
    },
    lock (finishes) {
      this.group.summary = finishes || this.lockDTO
      this.group.locked = true
      this.$emit('lock-click')
    },
    unlock () {
      this.group.summary = null
      this.group.locked = false
      this.$emit('lock-click')
    },
    print () {
      window.print()
    }
  },
  components: {
    SummaryTable,
    ManualFinish
  }
}
</script>

<style scoped>
.sm {
  font-size: smaller !important;
}
.xs {
  font-size: x-small;
}
.forfeit {
  text-decoration: line-through;
}
.v-avatar span {
  font-size: 13px;
}
.nested td {
  border: none !important;
}
.nested td > div {
  line-height: initial;
}
</style>
